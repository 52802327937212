import React from 'react';
import classNames from 'clsx';
import Heading from '~/core/ui/Heading';

import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from '~/core/ui/Breadcrumb';
import { Link } from '@remix-run/react';

type PageTitle = string | React.ReactNode | React.ReactNode[];

export function Page(
  props: React.PropsWithChildren<{
    className?: string;
    sidebar?: React.ReactNode;
    contentContainerClassName?: string;
    onScroll?: (el: HTMLDivElement) => void;
  }>,
) {
  return (
    <div className={props.className}>
      <div className="hidden lg:block">{props.sidebar}</div>

      <div
        onScroll={(e) => props?.onScroll?.(e.currentTarget)}
        className={classNames(
          props.contentContainerClassName,
          `mx-auto flex flex-col h-full min-h-screen overflow-y-auto`,
        )}
      >
        {props.children}
      </div>
    </div>
  );
}

export function PageBody(
  props: React.PropsWithChildren<{
    className?: string;
  }>,
) {
  const className = classNames(
    'w-full px-container flex flex-col flex-1',
    props.className,
  );

  return <div className={className}>{props.children}</div>;
}

interface PageHeaderTitleProps {
  title?: PageTitle;
  breadcrumbs?: {
    title: string;
    link?: string;
  }[];
}
function PageHeaderTitle({ title, breadcrumbs }: PageHeaderTitleProps) {
  return (
    <>
  {title && (
    <Heading type={4}>
        <span className="flex items-center">
          <span className="dark:text-white">{title}</span>
        </span>
      </Heading>
  )}
  {breadcrumbs && (
    <Breadcrumb>
      <BreadcrumbList>
        {breadcrumbs.map((titleElement, idx) => (
          <React.Fragment key={idx}>
            <BreadcrumbItem>
              {titleElement.link ? (
                <BreadcrumbLink asChild>
                  <Link to={titleElement.link}>
                    {titleElement.title}
                  </Link>
                </BreadcrumbLink>
              ) : (
                <BreadcrumbPage>{titleElement.title}</BreadcrumbPage>
              )}
            </BreadcrumbItem>
            {idx + 1 < breadcrumbs.length && <BreadcrumbSeparator />}
          </React.Fragment>
        ))}
      </BreadcrumbList>
    </Breadcrumb>
  )}
    </>
  );
}

export function PageHeader({
  children,
  title,
  description,
  breadcrumbs,
  mobileNavigation,
}: React.PropsWithChildren<{
  title: PageTitle;
  description?: string | React.ReactNode;
  breadcrumbs?: {
    title: string;
    link?: string;
  }[];
  mobileNavigation?: React.ReactNode;
}>) {
  return (
    <div className="p-container flex items-start justify-between">
      <div
        className={
          'flex space-x-2 items-center lg:items-start lg:flex-col' +
          ' lg:space-x-0'
        }
      >
        <div className="flex items-center lg:hidden">{mobileNavigation}</div>
        <PageHeaderTitle title={title} breadcrumbs={breadcrumbs} />

        <Heading type={5} className="hidden lg:block">
          <span className="font-normal text-gray-600 dark:text-gray-400">
            {description}
          </span>
        </Heading>
      </div>

      {children}
    </div>
  );
}
